window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});


//VWO conversion metric
let shadowRoot;
window.addEventListener('heyflow-init', (event) => {
    shadowRoot = (
        document.querySelector(`[flow-id="${event.detail.flowID}"]`) &&
        document.querySelector(`[flow-id="${event.detail.flowID}"]`).shadowRoot
    ) || document;

    shadowRoot.getElementById('button-2df97c1c').addEventListener('click', function() {
        window.VWO = window.VWO || [];
        VWO.event = VWO.event || function () {
            VWO.push(['event'].concat([].slice.call(arguments)))
        };
        VWO.event('form-submit-fbr2');
    });
});

// zip code validation
window.addEventListener('heyflow-init', (event) => {
    shadowRoot = 
        (document.querySelector(`[flow-id="${event.detail.flowID}"]`) &&
        document.querySelector(`[flow-id="${event.detail.flowID}"]`)
        .shadowRoot) ||
        document;
    
    const continueButton = shadowRoot.getElementById('id-91f99d0d');
    if (continueButton) {
        let validationInProgress = false;
        let isValidZip = false;
        
        continueButton.addEventListener('click', async (event) => {
            if (!validationInProgress) {
                event.preventDefault();
                event.stopPropagation();
                validationInProgress = true;
                
                const zipInput = shadowRoot.getElementById('input-69545dc3');
                const zipCode = parseInt(zipInput.value);
                
                const existingError = shadowRoot.getElementById('zip-error');
                if (existingError) {
                    existingError.remove();
                }
                
                try {
                    const response = await fetch(`https://api.wsh-lighthouse-e1-prod.com/horizon-api-prod/api/branch?zipcode=${zipCode}`);
                    const data = await response.json();
                    
                    if (!data || data.error) {
                        const errorDiv = document.createElement('div');
                        errorDiv.id = 'zip-error';
                        errorDiv.style.color = 'red';
                        errorDiv.style.marginTop = '8px';
                        errorDiv.style.fontSize = '14px';
                        errorDiv.textContent = 'This zip code is not in our service area';
                        zipInput.parentNode.appendChild(errorDiv);
                        validationInProgress = false;
                        isValidZip = false;
                    } else {
                        isValidZip = true;
                        validationInProgress = false;
                        continueButton.click();
                    }
                } catch (error) {
                    console.error('API Error:', error);
                    const errorDiv = document.createElement('div');
                    errorDiv.id = 'zip-error';
                    errorDiv.style.color = 'red';
                    errorDiv.style.marginTop = '8px';
                    errorDiv.style.fontSize = '14px';
                    errorDiv.textContent = 'Unable to validate zip code. Please try again.';
                    zipInput.parentNode.appendChild(errorDiv);
                    validationInProgress = false;
                    isValidZip = false;
                }
            } else if (!isValidZip) {
                event.preventDefault();
                event.stopPropagation();
            }
        }, true);
    }
});